import {Col, Modal, Row} from "antd";
import {DeblockTable} from "./DeblockTable";
import api from "../../../lib/util";
import {NotifyError} from "../../../lib/notify";
import {useState} from "react";
import TextArea from "antd/lib/input/TextArea";

export function Defect(props) {

    const [comment, setComment] = useState();

    const title = props.title || 'Указать бракованные пробирки';

    function onOK() {
        const ids = {ids: props.data.map((d) => d.id), comment};
        api.postJSON('/api/portion/waste', ids).then(response => {
            if (response.errors) {
                const values = Object.values(response.errors);
                values.forEach((error) => {
                    NotifyError(error);
                })
            } else {
                props.onHide();
            }
        });
    }

    return <>
        <Modal
            title={title}
            open={true}
            width={1300}
            onOk={onOK}
            onCancel={props.onHide}
            okText={'OK'}
            cancelText={'Отмена'}
            destroyOnClose={true}
        >
            <Row>
                <Col span={24} style={{padding: '10px 0'}}>
                        <div>Комментарий</div>
                        <TextArea value={comment} onChange={(e) => setComment(e.target.value)}/>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DeblockTable dataSource={props.data} columns={props.columns}/>
                </Col>
            </Row>
        </Modal>
    </>

}
