import React, {useEffect} from "react";
import PageHeader from "../../components/mock/PageHeader";
import api from "../../lib/util";
import {Button, Col, Form, Row, Select, Table} from "antd";

const renderMaterials = (materials) => {
    if (Array.isArray(materials)) {
        return <table>
            <thead>
            <tr>
                <th>Название</th>
                <th>Кат. №</th>
                <th>Партия</th>
                <th>Производитель</th>
            </tr>
            </thead>
            <tbody>
            {materials.map((item) => {
                return <tr key={item.id}>
                    <td>{item.material_name}</td>
                    <td>{item.catalogue_number}</td>
                    <td>{item.batch_number}
                    </td>
                    <td>{item.manufacturer}</td>
                </tr>;
            })}
            </tbody>
        </table>
    } else {
        return '';
    }
}

const PortionHistory = (props) => {

    const [loading, setLoading] = React.useState(false);

    const [portion, setPortion] = React.useState([]);
    const [filterStage, setFilterStage] = React.useState([]);
    const [events, setEvents] = React.useState([]);
    const [filterOperation, setFilterOperation] = React.useState([]);
    const [allExpanded, setAllExpanded] = React.useState(false);

    const load = () => {
        setLoading(true);
        api.getJSON(`/api/portion/history-by-events/${props.match.params.id}`).then((data) => {
            const portion = data;
            const portionData = {
                portion_id: portion.id,
                olig_name: portion.oligonucleotid.full_sequence,
                prefix: portion.prefix,
            };
            setPortion(portionData);
            let index = 1;
            const e = data.events.map((item) => {
                return {
                    index: index++,
                    name: item.name,
                    created_at: item.created_at,
                    created_by: item.author.name,
                    events: item.events,
                    data: JSON.stringify(item),
                }
            });
            setEvents(e);
        }).finally(() => {
            setLoading(false);
        });
    }

    const renderEvents = (events) => {
        if (!events) {
            return '';
        }
        return events.map((item) => {
            return <div key={item.id}>
                <div>{item.name}</div>
                <div>{item.created_at}</div>
                <div>{item.author.name}</div>
                <div>{item.data}</div>
                <div>{renderEvents(item.events)}</div>
            </div>;
        });
    }

    const getColumns = () => [
        // {
        //     dataIndex: 'portion_id', title: 'ID', width: 40, render: (value) => {
        //         return <a href={`/portion/${value}`}>{value}</a>
        //     }
        // },
        {dataIndex: 'index', title: '№', width: 40},
        {dataIndex: 'name', title: 'Название', width: 200},
        {
            dataIndex: 'created_at', title: 'Дата', width: 200, render: (value) => {
                return value ? new Date(value).toLocaleString() : '';
            }
        },
        {
            dataIndex: 'created_by', title: 'Пользователь', width: 200,
        },
        {
            dataIndex: 'events', title: 'События', width: 200, render: (value) => {
                return renderEvents(value);
            }
        },

        {
            dataIndex: 'data', title: 'Данные',
        }
        // {
        //     dataIndex: 'stage', title: 'Этап', width: 400, children: [
        //         {
        //             dataIndex: 'stage',
        //             title: 'Дата',
        //             width: 100,
        //             render: (value) => {
        //                 const date = (value?.date ? dayjs(value.date).format('LLL') : '') || '';
        //                 return date;
        //             }
        //         },
        //         {
        //             dataIndex: 'stage', title: 'Название', width: 300, render: (value, record) => {
        //                 return value?.displayName || '';
        //             }
        //         },
        //     ]
        // },
        // {
        //     dataIndex: 'operation', title: 'Операция', width: 1470, children: [
        //         {
        //             dataIndex: 'operation', title: 'Создание', width: 150,
        //             render: (value) => {
        //                 return dayjs(value?.created_at).format('LLL') || '';
        //             }
        //         },
        //         {
        //             dataIndex: 'operation', title: 'Послед. изм.', width: 150,
        //             render: (value) => {
        //                 return dayjs(value?.updated_at).format('LLL') || '';
        //             }
        //         },
        //         {
        //             dataIndex: 'operation',
        //             title: 'Название/Параметры',
        //             width: 470,
        //         },
        //         {
        //             dataIndex: 'operation',
        //             title: 'Материалы',
        //             width: 400,
        //         },
        //         {
        //             dataIndex: 'operation',
        //             title: 'Пользователь',
        //             width: 300,
        //             render: (value) => {
        //                 const updated_at = value?.pivot?.updated_at || '';
        //                 let result = value?.author?.name || '';
        //                 if (updated_at !== '') {
        //                     result += ` (${dayjs(updated_at).format('LLL')})`;
        //                 }
        //                 return result;
        //             }
        //         },
        //     ]
        // },
        // {}
    ];

    useEffect(() => {
        const id = props.match.params.id;
        if (id) {
            load();
        }
    }, []);

    const renderPortionHeader = () => {
        return <table style={{width: '100%'}}>
            <thead>
            <tr>
                <th align={'left'}>ID</th>
                <th align={'left'}>Название</th>
                <th align={'left'}>Префикс</th>
                <th align={'left'}>Рабочая последовательность</th>
                <th align={'left'}>Целевая последовательность</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td align={'left'}>{portion?.portion_id}</td>
                <td align={'left'}>{portion?.olig_name || '-'}</td>
                <td align={'left'}>{portion?.prefix || '-'}</td>
                <td align={'left'}>{portion?.work_sequence}</td>
                <td align={'left'}>{portion?.target_sequence}</td>
            </tr>
            </tbody>
        </table>;
    }

    return <>
        <PageHeader title={"История пробирки"} showFavorite={false}/>
        <Row style={{margin: 24}}>
            <Col span={20}>
                <Form layout={'inline'}>
                    <Form.Item label={"Этап"}>
                        <Select value={filterStage} options={[]} style={{width: 400}}
                                onChange={(value) => {
                                    setFilterStage(value);
                                }}
                                allowClear={true}
                                mode={'multiple'}
                        />
                    </Form.Item>
                    <Form.Item label={"Операция"}>
                        <Select value={filterOperation} options={[]} style={{width: 800}}
                                onChange={(value) => {
                                    setFilterOperation(value);
                                }}
                                allowClear={true}
                                mode={'multiple'}
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col span={4} style={{display: 'flex', justifyContent: 'end'}}>
                <Button onClick={() => {
                    setAllExpanded(!allExpanded);
                }}>{allExpanded ? 'Свернуть все' : 'Развернуть все'}
                </Button>
            </Col>
        </Row>
        <Row style={{margin: 24}}>
            <Col span={24}>
                {renderPortionHeader()}
            </Col>
        </Row>
        <Row style={{margin: 24}}>
            <Col span={24}>
                <Table rowKey={"index"} columns={getColumns()} dataSource={events}
                       loading={loading} pagination={false}
                       className={'bold-header'}
                       scroll={{x: 2000}}
                />
            </Col>
        </Row>
    </>;

}
export default PortionHistory;
