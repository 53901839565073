import StageManager from "./StageManager";
import {useContext, useState} from "react";
import {
    getMassResultsColumns,
    getMassSpectrColumns,
    mapDataFunction,
    operationMarkup as oms, operations,
    operations as ops, useColumns
} from "./Shared";
import {Radio, Select, Space} from "antd";
import MassLoadResults from "./MassLoadResults";
import {PermissionsContext} from "../../lib/PermissionsContext";

const visibleModes = {
    NonCompleted: 2,
    Completed: 1,
    Uploaded: 3,
    All: 4,
}

const visibleOptions = [
    {label: 'В ожидании', value: visibleModes.NonCompleted},
    {label: 'Выгруженные', value: visibleModes.Uploaded},
    {label: 'Завершенные', value: visibleModes.Completed},
    {label: 'Все', value: visibleModes.All},
]

const filterGroups = [
    {value: 'syn', label: 'SYN (Пробирки с деблокирования)'},
    {value: 'ie', label: 'IE (Пробирки с хроматографирования (фракции))'},
    {value: 'fp', label: 'FP (Пробирки с финального контроля)'},
    {value: 'sp', label: 'SP (Все остальные пробирки)'},
];

export default function Mass() {

    const [visibleMode, setVisibleMode] = useState(visibleModes.NonCompleted);
    const [loadResultsVisible, setLoadResultsVisible] = useState(false);
    const [tabletFromFilter, setTabletFromFilter] = useState(null);

    const {columns, filters} = useColumns();
    const restColumns = columns.concat(getMassSpectrColumns(), getMassResultsColumns());

    const {hasPermission} = useContext(PermissionsContext);
    const canEdit = hasPermission('mass-spectrometry.edit');


    function getColumns() {
        return restColumns.filter((item) => {
            return item.dataIndex !== 'previous_stage';
        });
    }

    function getOperations() {
        return [
            [
                {id: ops.MASS_SPECTR, title: 'Экспорт на масс-спектр'},
                oms[ops.MASS_RESULTS],
                {
                    title: 'Загрузить результаты', handler: () => {
                        setLoadResultsVisible(true);
                    }
                },
                oms[ops.CANCEL_ALIQUOT],
            ],
        ];
    }

    function getExtraFilter(onChange) {
        return <>
            <Space direction="horizontal" size={'large'}>
                <label>
                    Фильтр по группе:
                    <Select options={filterGroups} onChange={setTabletFromFilter} style={{width: 500}}
                            allowClear={true} value={tabletFromFilter}
                    />
                </label>
                <label>
                    Показать:
                    <div><Radio.Group
                        options={visibleOptions} size={'small'}
                        onChange={(v) => {
                            setVisibleMode(v.target.value);
                            onChange(v.target.value);
                        }}
                        value={visibleMode}
                    /></div>
                </label>
            </Space>
        </>
    }

    function getQueryParams() {
        let result = [];
        if (visibleMode !== visibleModes.All) {
            result.push(`status=${visibleMode}`);
        }
        if (tabletFromFilter) {
            result.push(`tablet_from=${tabletFromFilter}`);
        }
        return result;
    }

    function customMapFunction(data) {
        data = mapDataFunction(data);
        data.forEach((item) => {
            item['current_stage_date'] = item['mass_spectr_date'];
        });
        return data;
    }

    return <><StageManager
        title={'Масс-спектрометрия'}
        operationButtons={getOperations()}
        columns={getColumns()}
        mapFucntion={customMapFunction}
        url={'/api/mass-spectrometry'}
        queryParams={getQueryParams()}
        filter={filters}
        extraFilter={getExtraFilter}
        canEdit={canEdit}
    />
        {loadResultsVisible && <MassLoadResults onHide={() => setLoadResultsVisible(false)}/>}
    </>;

}

